<template>
  <v-card fluid class="pa-10 preview">
    <v-row>
      <v-col cols="6" class=""> <b>Cliente</b> {{ data.cliente }}</v-col>

      <v-col cols="6" class="text-right">
        <h1>Opción {{ data.OpcionesSelecciondas[index - 1].nro_propuesta }}</h1>
        <b>Fecha</b> {{ fechaHoy() }}
      </v-col>

      <v-col cols="12" class="text-center t-bg py-1">
        DETALLES DEL SERVICIO
      </v-col>

      <v-col cols="12" class="text-center py-1">
        {{ data.cliente }} - {{ data.embarque }} - {{ data.icoterm }}
      </v-col>
      <v-col cols="12" class="text-center py-0">
        <hr />
      </v-col>
      <v-col cols="6" class="py-1">
        <b>PUERTO DE ORIGEN</b> {{ data.origen }}</v-col
      >
      <v-col cols="6" class="py-1">
        <b>PUERTO DE DESTINO</b> {{ data.destino }}</v-col
      >
      <v-col cols="12" class="text-center py-0">
        <hr />
      </v-col>
      <v-col class="py-1" cols="4">
        <b> FECHA VALIDÉZ:</b>
        {{ data.fechafin }}
      </v-col>
      <v-col class="py-1" cols="4">
        <b> TIEMPO EN TRÁNSITO:</b> {{ data.tiempoTransito }} días (s)
      </v-col>
      <v-col cols="4" class="py-1"><b> N° QUOTE:</b> {{ data.code }} </v-col>
      <v-col cols="12" class="text-center py-0">
        <hr />
      </v-col>
      <v-col cols="4" class="py-1 my-0">
        <p
          class="py-1 my-0"
          v-for="(serv, index) in lstServicesOne"
          :key="index"
        >
          {{ serv.name }} - {{ serv.estado }}
        </p>
      </v-col>
      <v-col cols="4" class="py-1 my-0">
        <p
          class="py-1 my-0"
          v-for="(serv, index) in lstServicesTwo"
          :key="index"
        >
          {{ serv.name }} - {{ serv.estado }}
        </p>
      </v-col>
      <v-col cols="4" class="py-1 my-0">
        <p
          class="py-1 my-0"
          v-for="(serv, index) in lstServicesTree"
          :key="index"
        >
          {{ serv.name }} - {{ serv.estado }}
        </p>
      </v-col>
      <v-col cols="12" class="text-center py-0">
        <hr />
      </v-col>
      <v-col cols="12" v-if="data.tipo != 'AGRUPADO' && data.isImport">
        <table
          width="100%"
          style="padding: 0 2rem; border-collapse: collapse"
          v-if="data.OpcionesSelecciondas[index - 1].totalFlete != '$0.00'"
        >
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="5">
                {{
                  data.TipoCostos.some((v) => v.codigo == "FL")
                    ? data.TipoCostos.filter((v) => v.codigo == "FL")[0].name
                    : ""
                }}
              </th>
              <th class="text-right" colspan="1">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element, index) in data.OpcionesSelecciondas[index - 1]
                .datosFlete"
              :key="index"
            >
              <td class="text-left" colspan="5">{{ element.name }}</td>
              <td class="text-right" colspan="1">{{ element.valor }}</td>
            </tr>

            <tr
              style="
                border-top: 1.5px solid black;
                border-bottom: 1.5px solid black;
              "
            >
              <td class="text-right" colspan="5">Total:</td>
              <td class="text-right" colspan="1">
                {{ data.OpcionesSelecciondas[index - 1].totalFlete }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col
        cols="12"
        v-if="
          data.tipo != 'AGRUPADO' &&
          data.OpcionesSelecciondas[index - 1].totalOrigen != '$0.00'
        "
      >
        <table width="100%" style="padding: 0 2rem; border-collapse: collapse">
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="5">
                {{
                  data.TipoCostos.some((v) => v.codigo == "OR")
                    ? data.TipoCostos.filter((v) => v.codigo == "OR")[0].name
                    : ""
                }}
              </th>
              <th class="text-right" colspan="1">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element, index) in data.OpcionesSelecciondas[index - 1]
                .datosOrigen"
              :key="index"
            >
              <td class="text-left" colspan="5">{{ element.name }}</td>
              <td class="text-right" colspan="1">{{ element.valor }}</td>
            </tr>

            <tr
              style="
                border-top: 1.5px solid black;
                border-bottom: 1.5px solid black;
              "
            >
              <td class="text-right" colspan="5">Total:</td>
              <td class="text-right" colspan="1">
                {{ data.OpcionesSelecciondas[index - 1].totalOrigen }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col
        cols="12"
        v-if="
          data.tipo != 'AGRUPADO' &&
          data.OpcionesSelecciondas[index - 1].totalLocales != '$0.00'
        "
      >
        <table width="100%" style="padding: 0 2rem; border-collapse: collapse">
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="5">
                {{
                  data.TipoCostos.some((v) => v.codigo == "LO")
                    ? data.TipoCostos.filter((v) => v.codigo == "LO")[0].name
                    : ""
                }}
              </th>
              <th class="text-right" colspan="1">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element, index) in data.OpcionesSelecciondas[index - 1]
                .datosLocales"
              :key="index"
            >
              <td class="text-left" colspan="5">{{ element.name }}</td>
              <td class="text-right" colspan="1">{{ element.valor }}</td>
            </tr>

            <tr
              style="
                border-top: 1.5px solid black;
                border-bottom: 1.5px solid black;
              "
            >
              <td class="text-right" colspan="5">Total:</td>
              <td class="text-right" colspan="1">
                {{ data.OpcionesSelecciondas[index - 1].totalLocales }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col
        cols="12"
        v-if="
          data.tipo != 'AGRUPADO' &&
          data.OpcionesSelecciondas[index - 1].totalAduanas != '$0.00'
        "
      >
        <table width="100%" style="padding: 0 2rem; border-collapse: collapse">
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="5">
                {{
                  data.TipoCostos.some((v) => v.codigo == "AD")
                    ? data.TipoCostos.filter((v) => v.codigo == "AD")[0].name
                    : ""
                }}
              </th>
              <th class="text-right" colspan="1">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element, index) in data.OpcionesSelecciondas[index - 1]
                .datosAduanas"
              :key="index"
            >
              <td class="text-left" colspan="5">{{ element.name }}</td>
              <td class="text-right" colspan="1">{{ element.valor }}</td>
            </tr>

            <tr
              style="
                border-top: 1.5px solid black;
                border-bottom: 1.5px solid black;
              "
            >
              <td class="text-right" colspan="5">Total:</td>
              <td class="text-right" colspan="1">
                {{ data.OpcionesSelecciondas[index - 1].totalAduanas }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col
        cols="12"
        v-if="
          data.tipo != 'AGRUPADO' &&
          !data.isImport &&
          data.OpcionesSelecciondas[index - 1].totalFlete != '$0.00'
        "
      >
        <table width="100%" style="padding: 0 2rem; border-collapse: collapse">
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="5">
                {{
                  data.TipoCostos.some((v) => v.codigo == "FL")
                    ? data.TipoCostos.filter((v) => v.codigo == "FL")[0].name
                    : ""
                }}
              </th>
              <th class="text-right" colspan="1">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element, index) in data.OpcionesSelecciondas[index - 1]
                .datosFlete"
              :key="index"
            >
              <td class="text-left" colspan="5">{{ element.name }}</td>
              <td class="text-right" colspan="1">{{ element.valor }}</td>
            </tr>

            <tr
              style="
                border-top: 1.5px solid black;
                border-bottom: 1.5px solid black;
              "
            >
              <td class="text-right" colspan="5">Total:</td>
              <td class="text-right" colspan="1">
                {{ data.OpcionesSelecciondas[index - 1].totalFlete }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col
        cols="12"
        v-if="
          data.tipo != 'AGRUPADO' &&
          data.OpcionesSelecciondas[index - 1].totalAlmacenes != '$0.00'
        "
      >
        <table width="100%" style="padding: 0 2rem; border-collapse: collapse">
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="5">
                {{
                  data.TipoCostos.some((v) => v.codigo == "AL")
                    ? data.TipoCostos.filter((v) => v.codigo == "AL")[0].name
                    : ""
                }}
              </th>
              <th class="text-right" colspan="1">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element, index) in data.OpcionesSelecciondas[index - 1]
                .datosAlmacenes"
              :key="index"
            >
              <td class="text-left" colspan="5">{{ element.name }}</td>
              <td class="text-right" colspan="1">{{ element.valor }}</td>
            </tr>

            <tr
              style="
                border-top: 1.5px solid black;
                border-bottom: 1.5px solid black;
              "
            >
              <td class="text-right" colspan="5">Total:</td>
              <td class="text-right" colspan="1">
                {{ data.OpcionesSelecciondas[index - 1].totalAlmacenes }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col
        cols="12"
        v-if="
          data.tipo != 'AGRUPADO' &&
          data.OpcionesSelecciondas[index - 1].totalGastosTercero != '$0.00'
        "
      >
        <table width="100%" style="padding: 0 2rem; border-collapse: collapse">
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="5">
                {{
                  data.TipoCostos.some((v) => v.codigo == "GT")
                    ? data.TipoCostos.filter((v) => v.codigo == "GT")[0].name
                    : ""
                }}
              </th>
              <th class="text-right" colspan="1">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element, index) in data.OpcionesSelecciondas[index - 1]
                .datosGastosTerceros"
              :key="index"
            >
              <td class="text-left" colspan="5">{{ element.name }}</td>
              <td class="text-right" colspan="1">{{ element.valor }}</td>
            </tr>

            <tr
              style="
                border-top: 1.5px solid black;
                border-bottom: 1.5px solid black;
              "
            >
              <td class="text-right" colspan="5">Total:</td>
              <td class="text-right" colspan="1">
                {{ data.OpcionesSelecciondas[index - 1].totalGastosTercero }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col cols="12" v-if="data.tipo == 'AGRUPADO'">
        <table width="100%" style="padding: 0 2rem; border-collapse: collapse">
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="6">CONCEPTOS DE GASTOS</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(concepto, index) in data.OpcionesSelecciondas[index - 1]
                .conceptos"
              :key="index"
            >
              <td class="text-left" colspan="6">{{ concepto.name }}</td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="10" class="st-bg py-1"> TOTAL SERVICIOS DE PIC CARGO </v-col>
      <v-col cols="2" class="text-right st-bg py-1">
        {{ data.OpcionesSelecciondas[index - 1].totalServicios }}
      </v-col>
      <v-col cols="12">
        <table width="100%" style="padding: 0 2rem; border-collapse: collapse">
          <thead>
            <tr class="subrayado">
              <th class="text-left" colspan="4">IMPUESTO A LA ADUANA</th>
              <th class="text-left" colspan="1"></th>
              <th class="text-right" colspan="1">MONTO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(imp, index) in data.OpcionesSelecciondas[index - 1]
                .impuesto"
              :key="index"
            >
              <td class="text-left" colspan="2">{{ imp.name }}</td>
              <td class="text-right" colspan="2">
                {{ imp.percentage ? imp.percentage : "" }}
              </td>
              <td class="text-right" colspan="2">${{ imp.valor }}</td>
            </tr>

            <tr
              style="
                border-top: 1.5px solid black;
                border-bottom: 1.5px solid black;
              "
            >
              <td class="text-right" colspan="5">Total:</td>
              <td class="text-right" colspan="1">
                {{ data.OpcionesSelecciondas[index - 1].totalImpuesto }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col cols="10" class="st-bg py-1">
        TOTAL DE IMPUESTO {{ data.iso == "9589" ? "SUNAT" : "ADUANA" }}
      </v-col>
      <v-col class="text-right st-bg py-1" cols="2">
        {{ data.OpcionesSelecciondas[index - 1].totalImpuesto }}
        <!-- {{ data.OpcionesSelecciondas[index - 1].total }} -->
      </v-col>
      <!--  -->
      <v-col cols="10"> IGV SOBRE SERVICIOS LOGISTICO </v-col>
      <v-col class="text-right" cols="2">
        {{ data.OpcionesSelecciondas[index - 1].totalImpuestosIGV }}
      </v-col>
      <v-col cols="10" class="st-ti py-1">
        TOTAL SERVICIO PIC CARGO
        {{
          data.OpcionesSelecciondas[index - 1].impuesto.length > 0
            ? "+ "
            : "IMPUESTO DE ADUANA SUNAT"
        }}
        IMPUESTO DE ADUANA SUNAT
      </v-col>
      <v-col class="text-right st-ti py-1" cols="2">
        {{ data.OpcionesSelecciondas[index - 1].total }}</v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  props: ["data", "index"],
  name: "PreviewQuote",
  data() {
    return {
      lstServices: [],
      lstServicesOne: [],
      lstServicesTwo: [],
      lstServicesTree: [],
      datosFlete: [],
    };
  },
  mounted() {
    this.lstServices = [];
    this.lstServicesOne = [];
    this.lstServicesTwo = [];
    this.lstServicesTree = [];

    this.generarData();
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault(); // Evita que se ejecute la acción predeterminada (imprimir)
      }
    },
    fechaHoy() {
      return moment().format("YYYY-MM-DD");
    },
    cerrar() {
      this.$emit("cerrar", false);
    },
    generarData() {
      window.addEventListener("keydown", this.handleKeyPress);
      let vm = this;
      let flete = vm.data.flete;
      let almacen = vm.data.almacen;
      let aduana = vm.data.aduana;
      let local = vm.data.local;
      let contenedor = vm.data.contenedor;

      if (Array.isArray(flete)) {
        flete.forEach((element) => {
          vm.lstServices.push({
            name: element.name,
            estado: element.estado,
          });
        });
      }

      if (Array.isArray(almacen)) {
        almacen.forEach((element) => {
          vm.lstServices.push({
            name: element.name,
            estado: element.estado,
          });
        });
      }

      if (Array.isArray(aduana)) {
        aduana.forEach((element) => {
          vm.lstServices.push({
            name: element.name,
            estado: element.estado,
          });
        });
      }

      if (Array.isArray(local)) {
        local.forEach((element) => {
          vm.lstServices.push({
            name: element.name,
            estado: element.estado,
          });
        });
      }

      if (Array.isArray(contenedor)) {
        contenedor.forEach((element) => {
          vm.lstServices.push({
            name: element.name,
            estado: element.valor,
          });
        });
      }
      if (vm.data.numerobultos) {
        vm.lstServices.push({
          name: "Nro Bultos:",
          estado: vm.data.numerobultos,
        });
      }
      if (vm.data.peso) {
        vm.lstServices.push({
          name: "Peso",
          estado: vm.data.peso,
        });
      }
      if (vm.data.volumen) {
        vm.lstServices.push({
          name: "Volumen",
          estado: vm.data.volumen,
        });
      }

      const chunkSize = Math.ceil(vm.lstServices.length / 3);
      vm.lstServicesOne = vm.lstServices.slice(0, chunkSize);
      vm.lstServicesTwo = vm.lstServices.slice(chunkSize, chunkSize * 2);
      vm.lstServicesTree = vm.lstServices.slice(chunkSize * 2);
    },
  },
  watch: {
    data() {
      this.lstServices = [];
      this.lstServicesOne = [];
      this.lstServicesTwo = [];
      this.lstServicesTree = [];

      this.generarData();
    },
  },
};
</script>

<style scoped>
* {
  font-size: 0.8rem;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.t-bg {
  background-color: #a43542 !important;
  color: white;
}
.st-bg {
  background: rgb(51, 63, 80) !important;
  color: #fff !important;
  font-weight: bold !important;
}
.st-ti {
  background: #2587b1 !important;
  color: #fff !important;
  font-weight: bold !important;
}
.preview {
  background-image: url("../../../public/img/no_valid.jpg");
  background-size: cover; /* Ajusta la imagen al tamaño de la sección */
  background-repeat: no-repeat; /* Evita que se repita la imagen */
}
.table[data-v-4339395b] {
  background-color: transparent;
}
</style>
